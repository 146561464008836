import { BannerTypeHandle } from '../types';

export const bannerImports = {
  [BannerTypeHandle.NOTIFICATION]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/notification/notificationBanner.vue'
      ),
  ),
  [BannerTypeHandle.URL_BANNER]: defineAsyncComponent(
    () =>
      import('~~/src/components/banner/components/banners/url/urlBanner.vue'),
  ),
  [BannerTypeHandle.HTML_BANNER]: defineAsyncComponent(
    () =>
      import('~~/src/components/banner/components/banners/html/htmlBanner.vue'),
  ),
  [BannerTypeHandle.IMAGE_BANNER]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/image/imageBanner.vue'
      ),
  ),
  [BannerTypeHandle.TEMPLATE_1]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/template1/template1Banner.vue'
      ),
  ),
  [BannerTypeHandle.TEMPLATE_2]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/template2/template2Banner.vue'
      ),
  ),
  [BannerTypeHandle.TEMPLATE_3]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/template3/template3Banner.vue'
      ),
  ),
  [BannerTypeHandle.TEMPLATE_4]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/template4/template4Banner.vue'
      ),
  ),
  [BannerTypeHandle.SALES_BAR]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/salesBar/salesBarBanner.vue'
      ),
  ),
  [BannerTypeHandle.WIDGET_NOTIFICATION]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/widgetNotification/widgetNotificationBanner.vue'
      ),
  ),
  [BannerTypeHandle.DEALS_OF_THE_WEEK]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/dealsOfTheWeek/dealsOfTheWeekBanner.vue'
      ),
  ),
  [BannerTypeHandle.PDP_ACTION_TEXT]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/pdpActionText/pdpActionTextBanner.vue'
      ),
  ),
  [BannerTypeHandle.PRODUCT_RECOMMENDATION]: defineAsyncComponent(
    () =>
      import(
        '~~/src/components/banner/components/banners/productRecommendation/productRecommendationBanner.vue'
      ),
  ),
};
