export enum BannerTypeHandle {
  NOTIFICATION = 'notification',
  IMAGE_BANNER = 'imageBanner',
  URL_BANNER = 'urlBanner',
  HTML_BANNER = 'htmlBanner',
  TEMPLATE_1 = 'template1',
  TEMPLATE_2 = 'template2',
  TEMPLATE_3 = 'template3',
  TEMPLATE_4 = 'template4',
  SALES_BAR = 'salesBar',
  WIDGET_NOTIFICATION = 'widgetNotification',
  DEALS_OF_THE_WEEK = 'dealsOfTheWeek',
  PRODUCT_RECOMMENDATION = 'productRecommendation',
  PDP_ACTION_TEXT = 'pdpActionText',
}

export interface BaseBannerData<T = BannerTypeHandle> {
  id?: string;
  typeHandle: T;
  name: string;
  slug?: string;
}
